.left-side{
  display: flex;
  flex-direction: column;
  background: rgba(103, 179, 226, 0.4);
  width: 440px;
  height: 100%;
  padding: 45px 40px 30px;

  .block{
    background: #074E7A;
    border-radius: 5px;
    padding: 20px;

    .title{font-size: 18px; line-height: 21px; color: #fff; margin-bottom: 18px;}
    .text{font-size: 15px; line-height: 30px; color: #FFF;}
  }

  .menu-link{
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: .3s ease;

    span{
      color: #fff;
      font-size: 12px;
      line-height: 14px;
      transition: .3s ease;

      &:first-child{font-size: 18px; line-height: 21px;}
    }

    &:hover{
      background: linear-gradient(180deg, #FCC538 0%, #EDA311 100%); 
      
      span{color: #333 }
    }
  }
}