.index-block {
  display: flex;
  padding: 0 60px;
  gap: 20px;

  > div {
    flex: 1;
  }

  .requests {
    h2 {
      font-weight: 400;
      font-size: 29px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
      margin: 48px 0 44px;
    }
  }
}

.no-requests {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.no-requests-image {
  margin-bottom: 12px;
  position: relative;
}
.no-requests-badge {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #f04d23;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-requests-badgetext {
  font-size: 20px;
  color: #fff;
}
.no-requests-text {
  color: #fff;
}

.visit-card {
  background-color: #67b3e2;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 13px;
}
.visit-cardtext {
  color: #fff;
  font-size: 15px;
  line-height: 30px;
}
.visit-cardtitle {
  font-size: 18px;
  margin-bottom: 17px;
}

.visit-list {
  height: calc(100vh - 85px - 34px - 92px - 94px - 30px);

  .ScrollbarsCustom-Wrapper {
    right: 60px !important;
  }
  .ScrollbarsCustom-TrackY {
    background: #fff !important;
    border-radius: 5px !important;
    top: 0 !important;
    height: 100% !important;
  }
  .ScrollbarsCustom-ThumbY {
    background: #fbc437 !important;
    border-radius: 5px !important;
  }
}
