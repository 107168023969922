#RegisterPage {
  width: 736px;
  height: 100%;
  margin: 0 auto;

  .register-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 36px;

    .column {
      flex: 1;

      &.one {
        max-width: 350px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: 44px;
        }

        .site-btn {
          margin: 0 auto;
        }
      }

      h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: center;

        color: #ffffff;
        margin: 40px 0;
      }

      > p {
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
        margin: 0 0 20px;
      }
    }
  }
}
