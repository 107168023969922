.site-btn{
  background: #074E7A;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: 64px;
  color:#fff;
  font-family: Roboto;
  border: none;
  font-size: 16px;
  padding: 0 20px;
  cursor: pointer;
  transition: .3s ease;

  &:hover{
    background: linear-gradient(180deg, #FCC538 0%, #EDA311 100%);
    color: #333333;
  }

  &.yellow {
    background: linear-gradient(180deg, #FCC538 0%, #EDA311 100%);
    color: #333333;

    &:hover{
      background: #074E7A;
      color: #fff;
    }
  }
}