.form-control {
  height: 64px;
  border: none;
  outline: none;
  flex: 1;
  padding: 0;
  padding-left: 20px;
  font-size: 16px;
  font-family: Roboto;
}

.form-control-row {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding-right: 20px;

  &.select-row {
    padding-right: 0;
  }

  .form-control-icon {
    width: 25px;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-select {
    padding-left: 20px;
    padding-right: 50px !important;
  }
}

.checkbox-row {
  margin-bottom: 23px;

  .checkbox-custom {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: linear-gradient(180deg, #fcd751 0%, #eea615 100%);
    border-radius: 5px;
    position: relative;

    &.checked::after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      position: absolute;
      background: url(../../assets/images/check-ico.svg) no-repeat center center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
    a {
      color: inherit;
    }
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 20px;
  }

  .MuiFormControlLabel-label {
    font-size: 16px;
    line-height: 19px;
    color: #fff;
  }
}

.hg-theme-default .hg-button.hg-functionBtn[data-skbtn="{lang}"]{max-width: 60px;}

.ico{display: inline-block; width: 20px; height: 20px;}
.ico.ico-enter{background: url(../../assets/images/enter-ico.svg) no-repeat center center / 100%;}
.ico.ico-backspace{background: url(../../assets/images/backspace-ico.svg) no-repeat center center / 100%;}