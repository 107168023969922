.visit-detail{ 
    width: calc(50% - 15px);
    margin: 0 auto;

    h1{margin: 48px 0 44px!important;}
}
.visit-block-inner{
    height: calc(100vh - 85px - 34px - 92px - 30px);

    .ScrollbarsCustom-Wrapper{right: 60px!important;}
    .ScrollbarsCustom-TrackY{
      background: #fff!important;
      border-radius: 5px!important;
      top: 0!important;
      height: 100%!important;
    }
    .ScrollbarsCustom-ThumbY{
      background: #FBC437!important;
      border-radius: 5px!important;
    }
}
.visit-block{ 
    padding: 23px 27px;
    background-color: #67B3E2;
    border-radius: 5px;
    margin-bottom: 23px;

    p{color: #fff}
}