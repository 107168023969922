header {
  display: flex;
  height: 85px;
  background: #074E7A;
  display: flex;

  .left-part{height: 100%; background: rgba(103, 179, 226, 0.14); width: 440px; display: flex; align-items:center; justify-content: center;}

  .back-btn{
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    &::before{
      content: "";
      background: url(../../assets/images/arr-left.svg) no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 25px;
    }
  }
}